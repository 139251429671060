import _coffeeScript from "coffee-script";
import _fs from "fs";
import _route from "./waypoint/route";
import _router from "./waypoint/router";
var exports = {};
var module = {
  exports: exports
};
var coffee = _coffeeScript;
var File = _fs;

if (!{}[".coffee"]) {
  ({})[".coffee"] = function (modul, filename) {
    var source = coffee.compile(File.readFileSync(filename, "utf8"));
    return module._compile(source.filename);
  };
}

module.exports = {
  "Route": _route,
  "Router": _router
};
export default module.exports;
export const Route = module.exports.Route;